import React from "react"

/* Import Local Styles */
import "./bandcamp.scss"

const Bandcamp = ({ html }) => (
  <div dangerouslySetInnerHTML={{ __html: html }} className="bandcamp" />
)

export default Bandcamp
