import React from "react"
import ReactPlayer from "react-player"
import BlockContent from "@sanity/block-content-to-react"

import "./bitchute.scss"

const Bitchute = ({ url, caption }) => (
  <div className="post-bitchute">
    <div className="player-wrapper">
      <iframe
        src={`${url.replace("bitchute.com/video", "bitchute.com/embed")}`}
        width="100%"
        height="360"
        scrolling="no"
        frameborder="0"
        style={{ border: "none" }}
      />
    </div>
    {caption && (
      <div className="post-bitchute-caption">
        <BlockContent blocks={caption} />
      </div>
    )}
  </div>
)

export default Bitchute
