import React from "react"

import AudioComponent from "../components/audio"

const AudioComponentSerializer = ({ node: { asset } }) => {
  return (
    <AudioComponent url={asset.url} title={asset.title ? asset.title : null} />
  )
}

export default AudioComponentSerializer
