import React from "react"

/* Import Local Styles */
import "./soundcloud.scss"

const Soundcloud = ({ html }) => (
  <div dangerouslySetInnerHTML={{ __html: html }} className="soundcloud" />
)

export default Soundcloud
