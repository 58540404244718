import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import BlockContent from "@sanity/block-content-to-react"

/* Import Local Styles */
import "./post-image.scss"

const sanityConfig = { projectId: "2pq7mrv0", dataset: "production" }

const toPlainText = (blocks = []) => {
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children, 
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    // join the paragraphs leaving split by two linebreaks
    .join('\n\n')
}


const PostImage = ({ image, caption }) => {
  const imageData = getGatsbyImageData(
    image.id,
    { maxWidth: 1440 },
    sanityConfig
  )

  return (
    <div className="post-image">
      <GatsbyImage image={imageData} alt={caption ? toPlainText(caption) : ""} />
      {caption && (
        <div className="post-image-caption">
          <BlockContent blocks={caption} />
        </div>
      )}
    </div>
  )
}

export default PostImage
