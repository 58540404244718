import React from "react"

import Vimeo from "../components/vimeo"

const VimeoSerializer = ({ node: vimeo }) => {
  if (vimeo && vimeo.url) {
    return <Vimeo url={vimeo.url} caption={vimeo.caption} />
  } else {
    return null
  }
}

export default VimeoSerializer
