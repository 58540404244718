import React from "react"
import classnames from "classnames"
import BlockContent from "@sanity/block-content-to-react"

/* Import Serializers */
import PostImageSerializer from "./serializers/postImage.js"
import BandcampSerializer from "./serializers/bandcamp.js"
import ApplePodcastSerializer from "./serializers/applePodcast"
import YouTubeSerializer from "./serializers/youtube.js"
import SoundcloudSerializer from "./serializers/soundcloud.js"
import VimeoSerializer from "./serializers/vimeo.js"
import BitchuteSerializer from "./serializers/bitchute.js"
import AudioComponentSerializer from "./serializers/audio.js"

/* Don't Forget to Update RSS Feed Serializer */
const serializers = {
  types: {
    postImage: PostImageSerializer,
    bandcamp: BandcampSerializer,
    applePodcast: ApplePodcastSerializer,
    youtube: YouTubeSerializer,
    vimeo: VimeoSerializer,
    soundcloud: SoundcloudSerializer,
    bitchute: BitchuteSerializer,
    postAudio: AudioComponentSerializer,
  },
}

export default ({ blocks, className = "" }) => (
  <BlockContent
    blocks={blocks}
    serializers={serializers}
    className={classnames("block-content", className)}
  />
)
