import React from "react"

/* Import Local Styles */
import "./apple-podcast.scss"

const ApplePodcast = ({ html }) => (
  <div dangerouslySetInnerHTML={{ __html: html }} className="apple-podcast" />
)

export default ApplePodcast
