import React from "react"

import Bitchute from "../components/bitchute"

const BitchuteSerializer = ({ node: bitchute }) => {
  if (bitchute && bitchute.url) {
    return <Bitchute url={bitchute.url} caption={bitchute.caption} />
  } else {
    return null
  }
}

export default BitchuteSerializer
