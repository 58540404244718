import React, { useEffect, useRef, useState } from "react"
import ReactAudioPlayer from "react-audio-player"

/* Import Local Styles */
import "./audio.scss"

const AudioComponent = ({ url, title }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [canPlay, setCanPlay] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const playerRef = useRef()

  console.log(duration)

  useEffect(() => {
    if (playerRef && playerRef.current) {
      console.log(playerRef.current)
    }

    const updateCurrentTimeInterval = setInterval(() => {
      setCurrentTime(playerRef.current.audioEl.current.currentTime)
      setDuration(playerRef.current.audioEl.current.duration)
    }, 100)

    return () => {
      clearInterval(updateCurrentTimeInterval)
    }
  }, [])

  const handleOnCanPlay = e => {
    setCanPlay(true)
    console.log("canPlay", canPlay)
    console.log("playerRef.current.audioEl", playerRef.current.audioEl)
    setDuration(playerRef.current.audioEl.current.duration)
  }

  const handleOnPause = () => {
    setIsPlaying(false)
    setCurrentTime(playerRef.current.audioEl.current.currentTime)
  }

  const handleOnPlay = () => {
    setIsPlaying(true)
    setCurrentTime(playerRef.current.audioEl.current.currentTime)
  }

  const handleOnClick = () => {
    if (isPlaying) {
      playerRef.current.audioEl.current.pause()
    } else {
      playerRef.current.audioEl.current.play()
    }
  }

  const formatSeconds = totalSeconds => {
    if (!totalSeconds) return "00:00"

    // 👇️ get number of full minutes
    const minutes = Math.floor(totalSeconds / 60)

    // 👇️ get remainder of seconds
    const seconds = Math.floor(totalSeconds % 60)

    function padTo2Digits(num) {
      return num.toString().padStart(2, "0")
    }

    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
  }

  const onScrub = value => {
    console.log("value", value)
    // Clear any timers already running
    // clearInterval(intervalRef.current)
    console.log("playerRef.current.audioEl", playerRef.current.audioEl)
    playerRef.current.audioEl.current.currentTime = value
    // setTrackProgress(audioRef.current.currentTime)
  }

  const onScrubEnd = () => {
    if (!isPlaying) {
      playerRef.current.audioEl.current.play()
    }
  }

  return (
    <>
      <div className="post-audio">
        <button
          className="post-audio--toggle-is-playing"
          onClick={handleOnClick}
          style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
        >
          {isPlaying ? "■" : "▶"}
        </button>
        <div className="post-audio--timeline">
          <input
            type="range"
            value={currentTime}
            step="1"
            min="0"
            max={duration ? duration : `${duration}`}
            className="progress"
            onChange={e => onScrub(e.target.value)}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
          />
        </div>
        <div className="post-audio--timecode">
          <span>
            {formatSeconds(currentTime)}/{formatSeconds(duration)}
          </span>
        </div>
      </div>
      <ReactAudioPlayer
        src={url}
        controls
        className="react-audio-player"
        ref={playerRef}
        onCanPlay={handleOnCanPlay}
        onPause={handleOnPause}
        onPlay={handleOnPlay}
      />
    </>
  )
}

export default AudioComponent
