import React from "react"

import YouTube from "../components/youtube"

const YouTubeSerializer = ({ node: youtube }) => {
  if (youtube && youtube.url) {
    return <YouTube url={youtube.url} caption={youtube.caption} />
  } else {
    return null
  }
}

export default YouTubeSerializer
