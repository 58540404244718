import React from "react"
import ReactPlayer from "react-player"
import BlockContent from "@sanity/block-content-to-react"

import "./youtube.scss"

const YouTube = ({ url, caption }) => (
  <div className="post-youtube">
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        width="100%"
        height="100%"
      />
    </div>
    {caption && (
      <div className="post-youtube-caption">
        <BlockContent blocks={caption} />
      </div>
    )}
  </div>
)

export default YouTube
