import React from "react"

import PostImage from "../components/postImage"

const PostImageSerializer = ({ node: postImage }) => {
  if (postImage && postImage.asset) {
    return <PostImage image={postImage.asset} caption={postImage.caption} />
  } else {
    return null
  }
}

export default PostImageSerializer
